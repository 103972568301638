import Lightbox from '@/Components/Common/Lightbox.jsx';
import DefaultCatchPhraseBox from '@/Pages/Configurator/Components/Themed/DefaultCatchPhraseBox.jsx';
import ImagesStyle from '@/Styles/images.js';
import {Box, Link as MuiLink, Typography} from '@mui/material';
import React, {memo} from 'react';

const decors = [
    {image: '/images/pages/configurator/decors/bedroom01.png', width: 700, height: 700},
    {image: '/images/pages/configurator/decors/bedroom02.jfif', width: 1000, height: 1000},
    {image: '/images/pages/configurator/decors/bedroom03.jfif', width: 1000, height: 429},
];

const DefaultBedroomsDetails = () => {
    return (
        <>
            <Box sx={{mb: {xs: 4, md: 6}}}>
                <Typography variant="fancy_h5" component="h2" sx={{textWrap: 'balance'}}>
                    Comment organiser la chambre parentale ?
                </Typography>
            </Box>

            <Lightbox
                id={'gallery-bedroom-organisation'}
                sx={(theme) => ({
                    display: 'grid',
                    gridTemplateColumns: 'auto',
                    gridTemplateAreas: `
                        "a a"
                        "b c"
                        "d d"
                        "e e"`,
                    gap: 2,
                    height: '100%',
                    width: '100%',
                    [theme.breakpoints.up('md')]: {
                        gridTemplateColumns: '1fr 1fr',
                        gridTemplateAreas: `
                            "a c"
                            "b c"
                            "d e"`,
                        gap: 4,
                    },
                    [theme.breakpoints.up('lg')]: {
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridTemplateAreas: `
                            "a c d"
                            "b c d"
                            "b c e"`,
                    },
                })}
            >
                <DefaultCatchPhraseBox
                    sx={{gridArea: 'a', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    <Typography variant="subtitle1" color="white" component="p">
                        Des rangements et un valet de chambre pour y déposer vos vêtements à reprendre demain.
                    </Typography>
                </DefaultCatchPhraseBox>

                <MuiLink
                    href={decors[0].image}
                    data-pswp-width={decors[0].width}
                    data-pswp-height={decors[0].height}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                        display: 'inline-block',
                        gridArea: 'b',
                    }}
                >
                    <Box component="img" src={decors[0].image} style={{...ImagesStyle.cover_center}} alt="" />
                </MuiLink>
                <MuiLink
                    href={decors[1].image}
                    data-pswp-width={decors[1].width}
                    data-pswp-height={decors[1].height}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                        display: 'inline-block',
                        gridArea: 'c',
                    }}
                >
                    <Box component="img" src={decors[1].image} style={{...ImagesStyle.cover_center}} alt="" />
                </MuiLink>
                <MuiLink
                    href={decors[2].image}
                    data-pswp-width={decors[2].width}
                    data-pswp-height={decors[2].height}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                        display: 'inline-block',
                        gridArea: 'd',
                    }}
                >
                    <Box component="img" src={decors[2].image} style={{...ImagesStyle.cover_center}} alt="" />
                </MuiLink>
                <DefaultCatchPhraseBox
                    sx={{gridArea: 'e', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    <Typography variant="subtitle1" color="white" component="p">
                        Choisissez des couleurs claires et chaudes pour favoriser la relaxation et votre sommeil.
                    </Typography>
                </DefaultCatchPhraseBox>
            </Lightbox>
        </>
    );
};

export default memo(DefaultBedroomsDetails);
